<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='名字'>
              <el-input v-model='searchCondition.name' placeholder='名字' clearable></el-input>
            </el-form-item>
            <el-form-item label='手机号'>
              <el-input v-model='searchCondition.mobile' placeholder='手机号' clearable></el-input>
            </el-form-item>
            <el-form-item label='钉钉用户ID'>
              <el-input v-model='searchCondition.userid' placeholder='员工在当前企业内的唯一标识' clearable></el-input>
            </el-form-item>
            <el-form-item label='职位信息'>
              <el-input v-model='searchCondition.position' placeholder='职位信息' clearable></el-input>
            </el-form-item>
            <!--            <el-form-item label='员工的邮箱'>-->
            <!--              <el-input v-model='searchCondition.email' placeholder='员工的邮箱' clearable></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label='员工的企业邮箱'>-->
            <!--              <el-input v-model='searchCondition.orgEmail' placeholder='员工的企业邮箱' clearable></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label='入职时间'>-->
            <!--              <el-input v-model='searchCondition.hiredDate' placeholder='入职时间' clearable></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label='是否离职'>-->
            <!--              <el-input v-model='searchCondition.is_leave' placeholder='是否离职' clearable></el-input>-->
            <!--            </el-form-item>-->

            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <!--    渲染表格-->
          <div class='default-table'>
            <MlPageTable :all-columns='defaultColumns'
                         :table-name='tableName'
                         :data-list='dataList'
                         :limit.sync='pageData.page_size'
                         :page.sync='pageData.current_page'
                         :total.sync='total'
                         :height='height'
                         highlight-current-row
                         @getList='getList'>
              <el-table-column align='center' width='120' label='操作'>
                <template slot-scope='{row}'>
                  <el-link type='primary' @click='refreshInfo(row)'>
                    <i class='el-icon-refresh'></i>更新</el-link>
                  <!--                  <el-button type='text' icon='el-icon-edit' @click='handleEdit(row)'>修改</el-button>-->
                  <!--                  <el-button type='text' icon='el-icon-delete' @click='handleDelete(row,$index)'>删除</el-button>-->
                </template>
              </el-table-column>
            </MlPageTable>
          </div>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters } from 'vuex'
import MlPageTable from '@/components/common/MlPageTable'

export default {
  name: 'DepartmentManageMembers',
  components: {
    PageHeaderLayout,
    ApeTable,
    ModalDialog,
    MlPageTable
  },
  data() {
    return {
      tableName: 'ding_user_info',
      loadingStaus: true,
      loading: false,
      searchCondition: {
        date_range: [],
        open_id: null,
        union_id: null,
        name: null,
        stateCode: null,
        mobile: null,
        tel: null,
        avatar: null,
        userid: null,
        position: null,
        email: null,
        orgEmail: null,
        hiredDate: null,
        jobnumber: null,
        workPlace: null,
        remark: null,
        isLeader: null,
        active: null,
        isAdmin: null,
        isHide: null,
        isBoss: null,
        order: null,
        extattr: null,
        is_leave: null,
        leave_date: null
      },
      dataList: [],
      total: 0,
      height: 800,
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [
        { hide: true, prop: 'id', label: '序号', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'open_id', label: '钉钉openID', width: 120, sortable: true, fixed: false },
        { hide: true, prop: 'union_id', label: '钉钉 unionid', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'name', label: '名字', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'stateCode', label: '国家地区码', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'mobile', label: '手机号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'tel', label: '分机号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'avatar', label: '头像', width: 120, sortable: true, fixed: false, type: 'image' },
        { hide: true, prop: 'userid', label: '钉钉用户ID', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'position', label: '职位信息', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'email', label: '员工的邮箱', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'orgEmail', label: '员工的企业邮箱', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'hiredDate', label: '入职时间', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'jobnumber', label: '员工工号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'workPlace', label: '办公地点', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'remark', label: '备注', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'isLeader', label: '是否是部门的主管', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'active', label: '是否活跃', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'isAdmin', label: '是否是企业的管理员', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'isHide', label: '是否隐藏号码', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'isBoss', label: '是否为企业的老板', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'order', label: '人员在此部门中的排序', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'is_leave', label: '是否离职', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'leave_date', label: '离职时间', width: 120, sortable: true, fixed: false },
        { hide: true, prop: 'extattr', label: '扩展属性', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'deleted_at', label: '删除时间', width: 120, sortable: false, fixed: false }
      ],
      dialogData: {
        visible: false,
        title: '',
        width: '360px',
        loading: true,
        loading_text: '',
        modal: true
      },
      columns: [
        {
          title: '姓名',
          value: 'name',
          width: 100
        },
        {
          title: '图片',
          type: 'image',
          value: 'avatar',
          width: 60,
          align: 'center'
        },
        {
          title: '手机号',
          value: 'mobile',
          width: 100
        },
        {
          title: '邮箱',
          value: 'email',
          width: 160
        },
        {
          title: '工号',
          value: 'jobnumber',
          width: 100
        },
        {
          title: '部门',
          value: 'dpt_name',
          width: 120
        },
        {
          title: '是否是部门的主管',
          value: 'isLeader',
          width: 60
        },
        {
          title: '职位',
          value: 'position',
          width: 60
        },
        {
          title: '入职时间',
          value: 'hired_date',
          width: 100
        },
        {
          title: '在职/离职',
          value: 'is_leave_alias',
          width: 100
        },
        {
          title: '离职时间',
          value: 'leave_date',
          width: 100
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      // 表格列表数据
      userList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset: 0,
      // 表单结构
      formData: { user_id: '' },
      // 表单验证
      rules: {
        mobile: [
          { required: true, message: '选择用户', trigger: 'blur', validator: this.autocompleteVerify }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    handleQuery() {
      this.$route.params
      this.searchCondition.dpt_id = this.$route.params.dpt_id
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      // sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    // 初始化用户列表
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getDingUserInfoList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    async syncDpt() {
      await this.$api.syncDptUsers({ dpt_id: this.$route.params.dpt_id })
      this.$message.success('已同步')
    },

    // 搜索用户,qs请求参数，cb回调函数，详见组件用法
    async searchUser(qs, cb) {
      let roleId = this.$route.params.dpt_id
      let list = await this.$api.rolesSearchUser(qs, roleId)
      cb(list)
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData.user_id = item.id
      this.formData.role_id = this.$route.params.role_id
      if (this.formData.user_id) {
        this.$refs['rolesUserForm'].validateField('mobile')
      }
    },
    // 初始化验证
    initMobileVerify() {
      this.formData.user_id = ''
      this.$refs['rolesUserForm'].validateField('mobile')
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['rolesUserForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initFormData()
    },

    // 搜索用户，自动补全验证
    autocompleteVerify(rule, value, callback) {
      if (rule.required && (!this.formData.user_id || !this.formData.mobile)) {
        callback(new Error(rule.message))
      }
      callback()
    },

    async refreshInfo(row) {
      //用户信息更新
      let info = await this.$api.refreshUserInfo({ userid: row.userid })
      if (info) {
        this.$notify.success('更新成功！')
      }
    }

  },
  mounted() {
    this.handleQuery()
  }
}
</script>

<style lang='stylus'>
.el-button
  margin-right 4px
  margin-bottom 4px

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0
  margin-right 12px

.el-select > .el-input
  width 300px

.fanhui-button
  float right
</style>
